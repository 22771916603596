












































import { Component, Vue } from "vue-property-decorator";
import BackButton from "@/components/BackButton.vue";
@Component({
    components: { BackButton },
})
export default class FaqWhatIsDStvXtraView extends Vue {}
